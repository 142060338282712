@import url('https://fonts.googleapis.com/css?family=Lato:400,300,700');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

/* breakpoints */
$breakpoint-1: 512px;
$breakpoint-X: 799px;
$breakpoint-2X: 1023px;
$breakpoint-2: 1024px;

/* colorset */
$azure: #66afe9;
$black: #000000;
$black-demi: #333;
$black-gray: #1d1d1d;
$blue: #337ab7;
$gray: gray;
$gray-light: #ccc;
$gray-white: #eee;
$green: #50D926;
$green-light: #9cf97c;
$red: #F00;
$light-red: rgb(255, 75, 75);
$yellow: #ffc20a;
$light-yellow: rgb(255, 255, 100);
$white: #FFF;
$yellow2: #ecca09;
$green2: #95c11f;
$gray2: #686762;
$yellow3: #ffc20a69;

/* fonts */
$font-stack: 'Lato', sans-serif;

/* mixins */
@mixin text-overlay {
    background: rgba(0, 0, 0, 0.75);
    display: block;
    padding: .5em;
    width: auto;
}

/* tags */
a {
    color: $blue;
    font-weight: bold;
    text-decoration: none;
}

blockquote {
    border-left: 3px solid $red;
    padding-left: .5em;
    text-align: left;
}

body {
    background: $white;
    color: $black;
    font: 100% $font-stack;
    letter-spacing: .01em;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 100%;
    min-height: 100%;
}

cite {
    background: $green;
    color: $white;
    font-style: normal;
    font-weight: bold;
    margin: 0;
    padding: .2rem;
}

form {
    font: 100% $font-stack;
    padding: 1em;

    fieldset {
        border: none;

        a {
            color: $black-demi;

            &:hover {
                color: $black;
            }
        }

        input,
        button,
        select,
        textarea {
            font: 100% $font-stack;
            border: 0;
            box-sizing: border-box;
            padding: .5em;
            width: 100%;
        }

        input[type="email"],
        input[type="date"],
        input[type="text"],
        input[type="password"],
        select,
        textarea {

            background: $gray-white;
            border: 1px solid $gray-light;

            &:focus {
                border-color: $azure;
                outline: 0;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
            }
        }

        textarea {
            font-size: .8em;
        }

        button {
            background: $yellow;
            border: 0px;
            border-radius: 1em !important;
            color: $black;

            &:disabled,
            &:hover {
                background: $yellow;
                color: $white !important;
            }

            &:disabled {
                cursor: not-allowed;
            }

            cursor: pointer;

            &.ko {
                color: $black;
            }

            &.ok {
                color: $black !important;
                font-weight: bold;
                padding: .5em 1.5em;
            }

            &:hover {
                color: $white !important;
            }

            &.spaced {
                margin-left: 1em;
                margin-right: 1em;

                @media (max-width: ($breakpoint-X)) {
                    margin: 1em;
                }

                @media (max-width: ($breakpoint-1)) {
                    margin: 1em .2em;
                    padding: .5em 1em;
                }
            }

            &.red {
                background-color: rgb(200, 0, 0);
            }
        }

        input[type='checkbox']:not(.switch),
        input[type='radio'] {
            display: none;

            &~label {
                vertical-align: middle;
            }

            &~label:hover {
                cursor: pointer;
            }

            &~label>span::before {
                background: $gray-white;
                border: 2px solid $gray;
                color: transparent;
                content: '\2713';
                display: inline-block;
                height: 1.2em;
                line-height: 1.2em;
                margin: .5em;
                text-align: center;
                padding: 0;
                width: 1.2em;
            }

            &:checked~label>span::before {
                background: $white;
                color: $black;
            }

        }

        label {
            display: block;
            font-size: .8em;
            margin: 0 auto;
            text-align: center;
            width: 75%;

            &.left {
                font-size: .7em;
                text-align: left;
                width: 100%;
            }
        }

        .radioOption {
            display: inline-block;

            img {
                width: 45px;
            }
        }

    }

    &.left {
        text-align: left;

        & label {
            font-size: 1em;
            margin: .25em 0;
            text-align: left;
        }
    }
}

table {
    background: $white;
    border: 0;
    border-collapse: collapse;
    font-size: .9em;
    margin: .5em 0;
    width: 100%;

    caption {
        margin: 1em 0;
        text-align: center;
    }

    tr {
        border: 1px solid $gray-light;

        td,
        th {
            border: 0;
            padding: .5em .25em;
        }

        th {
            text-align: center;
        }

        td {
            & i {
                display: inline-block;
                font-size: 1.2em !important;
                padding: .5em;
            }

            &.soloMobile {
                display: none;

                @media (max-width: ($breakpoint-X)) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-items: center;
                    text-align: center;
                }
            }
        }
    }

    & .head {
        display: none;
    }

    &.responsiveTable {
        table-layout: auto;

        & td.w27 {
            width: 27%;
        }

        @media (max-width: ($breakpoint-1)) {
            table-layout: initial;
            border: 0;
            display: block;
            width: 100%;

            & * {
                box-sizing: border-box !important;
            }

            & tbody,
            thead,
            tr {
                display: block;
                overflow: hidden;
                text-align: left;
                width: 100%;
            }

            & td {
                line-height: 1em;
                display: inline-block;
                text-align: left;
                width: 100%;

                &.tdw27 {
                    width: 100% !important;
                }

                &.tdw25 {
                    border-top: 2px solid $yellow3;
                    border-bottom: 2px solid $yellow3;
                    width: 14%;
                    height: 3em;
                    vertical-align: bottom;

                    & i {
                        padding-top: 3px;
                    }
                }
            }

            & th {
                display: none;
            }

            & tr {
                border: 2px solid $yellow3;
                border-radius: 5%;
                margin-bottom: 10px;
            }

            & .head {
                display: block;
                padding: 1em;
                margin-bottom: 1em;
                /*background: lightgray;*/
                font-weight: bold;
                width: 100%;
            }

            & thead tr {
                border: 0;
            }

            & tr.filters {
                border: 0;

                & .head {
                    background: $gray-white;
                    color: $black;
                    margin-bottom: 0;
                }
            }
        }
    }
}

/* generic classes */
.blackbox {
    background: $black;
    border: 4px solid $green;
    opacity: .9;
    padding: .25em;
}

.boxed {
    @include text-overlay;
    max-height: $breakpoint-1;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 1em;

    .simplebar-offset {
        width: 102%;

        @media (max-width: ($breakpoint-1)) {
            width: 105%;
        }
    }

    .simplebar-scrollbar {
        background: $green-light;
        opacity: .5;
    }
}

.light-boxed {
    box-sizing: border-box;
    margin: 1em 0;
    padding: 0 5% 0 0;

    @media (max-width: ($breakpoint-1)) {
        padding: 0;
    }
}

.boxed-text {
    li {
        margin: .5em 0;
    }

    li>big {
        line-height: 2em;
        text-decoration: underline;
    }

    width: 98%;
}

.error {
    color: $red;
    padding: .5em;
    text-align: center;
    white-space: pre-wrap;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.auto {
    width: auto;
}

.nopad {
    padding: 0 !important;
}

.sameLine {
    span {
        float: left;
    }

    span:last-child {
        float: right;
    }
}

.warning {
    color: orange;
    font-weight: bold;
    margin: .25em;
    font-size: 1.25em;
}

.off {
    color: $red;
}

.on {
    color: $green;
}

.standby {
    color: $yellow;
}

.nil {
    color: $gray-light;
}

.padp {
    padding-left: 1.5em !important;
    padding-right: 1.5em;
    text-align: left;

    @media (max-width: ($breakpoint-1)) {
        padding-left: .5em !important;
        padding-right: .5em;
    }
}

.horizontal-slider-association {
    & .slider-thumb {
        top: -4px !important;
    }

    & .slider-track {
        top: 30px !important;
    }
}

.buttonPanel {
    @media (max-width: ($breakpoint-X)) {
        & .w50 {
            width: 50% !important;
            display: inline-block !important;

            & small {
                font-size: .6em;
            }
        }
    }
}

.switchRack {
    & .w33 {
        padding-right: 0 !important;

        & label {
            font-size: .9em !important;
        }
    }

    @media (max-width: ($breakpoint-1)) {
        text-align: center;

        & .w33 {
            margin: 0 auto !important;
            max-width: 80%;
        }
    }
}

.controlPanelTable {
    & tbody {
        & tr {
            & td:first-child {
                text-align: left;
            }
        }
    }
}

.fsb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .5em 0;
    padding: .25em;
    text-align-last: left;

    &.hov:hover {
        background-color: lightgray;
    }

    & img {
        height: 1em;
        width: 1em;
        margin: 0 .5em;
    }

    & i {
        margin: 0 .5em;
        color: gray;

        &.fa-wifi {
            color: orange;
        }
    }
}

.icon {
    vertical-align: sub;

    &.xs-icon {
        width: 1.4em;
    }
}