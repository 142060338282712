@import "src/index.scss";

.outcome {
    box-sizing: border-box;
    color: $white;
    font-size: 1.1em;
    margin: 0;
    padding: .5em;
    padding-right: 2em;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    z-index: 99999;
    display: none;

    &.ok {
        background: $green;
        display: block;
    }

    &.ko {
        background: $red;
        display: block;
    }

    & .close {
        color: $white;
        font-size: 1em;
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}