@import "src/index.scss";

footer {
    bottom: 0;
    display: flex;
    clear: both;
    left: 0;
    height: 3vh;
    margin-top: -5vh;
    position: fixed;
    font-size: .6em;
    color: $black-gray;
    background-color: transparentize($color: $yellow, $amount: 0.8);
    text-align: center;
    width: 100%;
    z-index: 9999;
    @media (max-width: ($breakpoint-X)) {
        height: auto !important;
        position: relative;
        min-height: 50px;
    }
    & p {
        margin: 0;
        padding: .5em;
        &:first-child {
            flex: 1
        }
    }
}