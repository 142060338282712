@import "src/index.scss";

form {
    padding: 1em 0 !important;
}

.bottomBorder {
    padding: 1em 0;
    border-bottom: 1px solid black;
}

.grayHover {
    margin-bottom: .1em;
    &:hover {
        background-color: #dedede;
    }
}


.programLabel {
    padding: .2em !important;
    color: rgb(255, 255, 255);
    border-left: 0;
    border-radius: 0.5em;

    &.programLabelFW {
        width: 150px;
        @media (max-width: ($breakpoint-X)) {
            margin: 0;
        }
    }

    &.red {
        background: red;
        //text-shadow: 2px 1px 4px #000c;
    }
    &.yellow {
        background: #ffc20a;
        //text-shadow: 2px 1px 4px #000c;
    }
    &.green {
        background: #28aa28;
        //text-shadow: 2px 1px 4px #000c;
    }
    &.blue {
        background: azure;
    }
}

.superButton {
    display: block;
    background: $yellow;
    color: $black-gray !important;
    width: 100%;
    border-radius: 1em;
    padding: .75em 1em;
    box-sizing: border-box;
    border:  0;
    font: 100% $font-stack;
    text-align: center;
    cursor: pointer;
    margin: .5em 0;

    &:hover {
        color: $white !important;
    }

    &:disabled {
        background: $gray;
        color: $white !important;
    }

    &:over:enabled {
        background: $yellow;
        color: $black;
    }

}

.yellowButton {
    display: inline-block;
    background: $yellow;
    color: $black;
    width: auto;
    border-radius: 0.5em;
    padding: .75em 1em;


    &:hover {
        background: $yellow;
        color: $white !important;
    }

    &:disabled {
        background: $yellow;
        color: $black !important;
    }

    &.attivazioneTotaleButton{
        width: 50%;
    }
}

.switchButton {

    margin: 2em 0 1em;
    border-radius: 1em;
    
    & label {
        width: 100%;
    }

    & blockquote {
        display: inline-block;
        border-left: 0;
    }

    & input[type='checkbox'] {
        display: none;
      
        &~label>span {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 5px;
            margin: 0 1em;
            overflow: show;
            background: lightgray;
            padding: 0;
            border-radius:5px;
        }

        &~label>span>span {
            display: block;
            height: 30px;
            width: 30px;
            position: absolute;
            top: -12px;
            left: 0px;
            transition: .3s;
            border-radius: 50%;
            background: white;
            border: 0px solid black;
            box-shadow: 2px 1px 2px gray;
        }

        &:disabled~label>span>span {
            background: #dedede;
        }

        &:checked~label>span {
            background: green;  
        }

        &:checked~label>span>span {
            left: 50px;
            transition: .3s;
        }

        &~label {
            vertical-align: middle;
        }

        &~label:hover {
            cursor: pointer;
        }
    }

    &:not(.controlPanelButton) {
        @media (max-width: ($breakpoint-1)) {
            & input[type='checkbox'] {
                &~label {
                    text-align: center !important;
                }
                &~label>span {
                    display: block;
                    margin-bottom: .5em;
                    width: auto !important;
                }
                &~label>span>span {
                    height: 20px;
                    left: 0%;
                    top: -8px;
                    width: 20px;
                }
                &:checked~label>span>span {
                    left: 100%;
                }        
            }
        }    
    }

} 

.horizontal-slider {
    width: 100%;
    height: 100px;
     
    & .slider-thumb {
        font-size: 0.9em;
        text-align: center;
        background-color: white;
        color: white;
        cursor: move;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: grey;
        border-radius: 5px;
        padding: .5em;
        top:15px;
        color: black;
        position: relative;
        overflow: visible;

        &::after {
            display: block;
            border-radius: 100%;
            background: black;
            width: 10px;
            height: 10px;
            position: absolute;
            bottom: -10px;
            left: 47%;
            content: ' ';
        }
    }

    & .slider-track {
        top: 50px;
        height: 5px;
        background: lightgray;
        border-radius: 5px;
    }

}

.outputsRack {
    & .w25 {
        padding-top: 1.5em;
    }
    & .rack.borderBottom {
        padding-bottom: 1em;
        border-bottom: 1px solid orange;
    }
}
.checkBoxInline {
    & p {
        display: inline-block;
        padding: 0;
        & label {
            margin: 0 !important;
            text-align: center !important;
        }
    }
    &.spacedP {
        & p {
            margin-right: 1em;
            @media (max-width: ($breakpoint-1)) {
                margin-right: 0.2em;
            }
        }
    }
    @media (max-width: ($breakpoint-1)) {
        & span {
            display: block;
        }
    }

}

.loadingText {
    &:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
    }
}
      
@keyframes dots {
0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
    .25em 0 0 rgba(0,0,0,0),
    .5em 0 0 rgba(0,0,0,0);}
40% {
    color: black;
    text-shadow:
    .25em 0 0 rgba(0,0,0,0),
    .5em 0 0 rgba(0,0,0,0);}
60% {
    text-shadow:
    .25em 0 0 black,
    .5em 0 0 rgba(0,0,0,0);}
80%, 100% {
    text-shadow:
    .25em 0 0 black,
    .5em 0 0 black;}}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;

    & input[type=file] {
        cursor: pointer;
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

.customRows {
    & blockquote {
        display: inline-block;
        width: auto;
        margin: .5em;
    }
    & input {
        max-width: 40%;
    }

}
