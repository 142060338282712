@import "src/index.scss";

main {
    margin: 0px auto;
    min-height: 60vh;
    overflow: hidden;
    min-height: 98vh;
    padding: 0;

    display: block;

    section {
        margin: 0 auto;
        max-width: $breakpoint-1;
        width: auto;

        div.grid {
            display: grid;
            grid-gap: 8px;
            grid-auto-rows: minmax(60px, auto);
            grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
            grid-auto-flow: dense;

            @media (max-width: ($breakpoint-1)) {
                grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            }
        }

        & p {
            margin: .5em 0px;
        }

        &.large {
            max-width: $breakpoint-2;
        }
    }

    &.private {
        background: url('images/background.png') no-repeat;
        background-size: 100%;
        margin-left: 200px;

        @media (max-width: ($breakpoint-2X)) {
            margin-left: 0;
        }

        & a {
            color: $black;

            &:hover {
                color: $blue;
            }
        }

        & section {
            box-sizing: border-box;
            max-width: 100%;
            padding: .5em;
            width: 100%;
        }

        .innerSection {

            h1,
            h2 {
                background: $yellow;
                color: $black;
                font-size: 1em;
                padding: 1em;
                border-radius: 1em;
                text-align: center;
                font-family: 'Roboto', sans-serif;
            }

            h2 {
                &.tab {

                    &.disabled {
                        background: $gray-white;
                        color: $black;
                        cursor: pointer;

                    }

                    &.tastiImpianti {
                        @media (max-width: ($breakpoint-1)) {
                            border-top-left-radius: 1em !important;
                            border-bottom-left-radius: 1em !important;
                            border-top-right-radius: 1em !important;
                            border-bottom-right-radius: 1em !important;
                        }

                    }

                }
            }

            h3 {
                text-align: center !important;
                font-size: 1.8em;
                padding: 0;
                margin-bottom: 0em;
                margin-top: 0em;
                font-family: 'Roboto', sans-serif;
                text-decoration: underline;
                text-decoration-color: $yellow;
                text-decoration-thickness: 0.1em;
                text-underline-offset: 0.3em;
            }

            h6 {
                background: $yellow;
                color: $black;
                font-size: 1em;
                padding: 1em;
                border-radius: 1em;
                text-align: center;
                font-family: 'Roboto', sans-serif;
                width: 93%;
            }

        }

        .formSection {
            fieldset {
                margin: 0;
                width: auto;
            }

            h3 {
                font-size: 1.4em;
                margin: .5em 0;
                padding: .25em 0;
                text-align: center !important;
            }

            h4 {
                margin: 2em 0 0;
                padding: 0 1em;
            }

            label {
                font-size: 1em;
                text-align: left;
                width: 75%;

                &.inline {
                    display: inline;
                    font-size: .9em;
                    margin: 0;
                }

                @media (max-width: ($breakpoint-1)) {
                    width: 100%;
                }
            }

            input[type="email"],
            input[type="date"],
            input[type="password"],
            input[type="text"],
            select,
            textarea {
                background: $white;
                width: 100%;

                &:disabled {
                    background: $gray-white;
                }
            }

            .rack {
                >div {
                    padding-right: 1em;
                }
            }

        }

        .box {
            padding: 1em;
        }

        .rack {
            clear: both;
            margin-bottom: .5em;
            overflow: hidden;
            text-align: left;
        }

        .w20 {
            box-sizing: border-box;
            float: left;
            width: 20%;

            @media (max-width: ($breakpoint-X)) {
                display: inline-block;
                float: none;
                width: 50%;
            }
        }

        .w25 {
            box-sizing: border-box;
            float: left;
            width: 25%;
            border-radius: 1em;

            @media (max-width: ($breakpoint-X)) {
                display: inline-block;
                float: none;
                width: 50%;
            }
        }

        .w33 {
            box-sizing: border-box;
            float: left;
            width: 33%;

            @media (max-width: ($breakpoint-X)) {
                float: none;
                width: 100%;
            }
        }


        .w30 {
            box-sizing: border-box;
            float: left;
            width: 30%;

            @media (max-width: ($breakpoint-X)) {
                float: none;
                width: 100%;
            }
        }

        .w40 {
            box-sizing: border-box;
            float: left;
            width: 40%;

            @media (max-width: ($breakpoint-X)) {
                float: none;
                width: 100%;
            }
        }

        .w50 {
            box-sizing: border-box;
            float: left;
            width: 50%;

            @media (max-width: ($breakpoint-X)) {
                float: none;
                width: 100%;
            }
        }

        .w60 {
            box-sizing: 140%;
            float: left;
            width: 60%;

            @media (max-width: ($breakpoint-X)) {

                float: none;
                width: 100%;
            }
        }

        .w66 {
            box-sizing: border-box;
            float: left;
            width: 66%;

            @media (max-width: ($breakpoint-X)) {
                float: none;
                width: 100%;
            }
        }

        .w75 {
            box-sizing: border-box;
            float: left;
            width: 75%;

            @media (max-width: ($breakpoint-X)) {
                float: none;
                width: 100%;
            }
        }

        .w100 {
            box-sizing: border-box;
            width: 100%;
        }

        .w95 {
            box-sizing: border-box;
            width: 100%;
            text-align: center;
            float: none;
        }

        .stackableW33 {

            & .w33 {
                padding-right: 1em;

                @media (max-width: ($breakpoint-1)) {
                    margin: 2em 0;
                    width: 100%;
                }
            }

        }

        .outputsRack {

            & .w33 {
                @media (max-width: ($breakpoint-1)) {
                    margin: 1em 0;
                }
            }

        }
    }

    .nopad {
        padding: 0 !important;
    }

    &.wide {
        margin-left: 0px !important;

        @media (max-width: ($breakpoint-2X)) {
            nav {
                display: none !important;
            }
        }
    }

    &.public {
        background: url('images/back-left-top.png') left top no-repeat, url('images/back-right-bottom.png') right bottom no-repeat;

        display: flex;
        align-items: center;
        justify-content: center;

        & section {
            max-width: 65%;
            width: 65%;

            &>div {
                display: inline-block;
                vertical-align: top;
                width: 50%;

                @media (max-width: ($breakpoint-X)) {
                    width: 100%;
                }

                & img.mainLogo {
                    display: block;
                    margin: 0 auto;
                    max-width: 75%;
                }
            }
        }
    }

    form[name='signup'] {
        p {
            text-align: left;
        }
    }

    .wizard {
        margin: 1em 0;
        text-align: center;

        & i {
            background: $gray-white;
            border-radius: 100%;
            color: $white;
            font-size: 1.75em;
            min-width: 1em;
            padding: .5em;
            width: auto;

            &.enabled {
                background: $yellow;
                cursor: pointer;
            }
        }

        & span {
            display: block;
            font-size: .8em;
            text-transform: uppercase;
        }

        & .w20 {
            background: transparent;
            position: relative;
            z-index: 1;

            @media (max-width: ($breakpoint-1)) {
                min-height: 100px;

                &:not(.step-enabled) {
                    display: none;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 45%;
                left: 0;
                border-top: .2px solid $gray-white;
                width: 100%;
                transform: translateY(-45%);
                z-index: -1;
            }

            &:first-child {
                &::before {
                    left: 50%;
                }
            }

            &:last-child {
                &::before {
                    width: 50%;
                }
            }

        }
    }

    .react-datepicker-wrapper {
        width: 100% !important
    }

    .preview {
        max-height: 200px;
        max-width: 200px;
    }

    .marker-label {
        margin-left: -35px;
        margin-top: 12px;
        background-color: #333333;
        padding: 1px 5px;
        border-radius: 8px 0 8px 0;
        width: 70px;
        text-align: center;
        box-shadow: 0px 1px 2px #333;
        font-weight: bold;
        color: #F3B90C;
        text-transform: uppercase;
        font-size: 9px;
    }

    .infowindow {
        font: 100% $font-stack;
        width: 400px;

        & img {
            display: block;
            max-height: 125px;
            max-width: 125px;
        }

        & h3 {
            margin: .5em 0;

            & img,
            & i {
                display: inline-block;
            }
        }

        & .iconBar {
            font-size: 1.5em;
            text-align: center;
            border-top: solid 1px black;

            & i,
            & a,
            & img {
                display: inline-block;
                margin: .2em .5em;
                vertical-align: middle;
            }
        }

        @media (max-width: ($breakpoint-1)) {
            font-size: .8em;
            width: 100%;

            & img {
                display: block;
                max-height: 80px;
                width: 100%;
            }

            & .iconBar {
                font-size: 1em;
            }
        }
    }
}