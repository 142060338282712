@import 'src/index.scss';

.dialog {
	width: 100%;
  height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
  background: rgba( 192, 192, 192, 0.65 );
  
  & .content {
    box-sizing: border-box;
    padding: .5em;
    width: 300px;
    position: absolute;
    top: 25%;
    left: 50%;
    margin-left: -150px;
    background: $white;
    border: 1px solid $black;

    h3 {
      text-align: center;
      margin: 0;
      width: 100% !important;
    }

    form, fieldset {
      padding: 0 !important;
      text-align: center;
    }

    p:first-of-type { 
      margin: 1.2em 0;
    }

    &.wide {
      width: 60%;
      left: 40%;
      transform: translateX(-10%);

      & fieldset {
        margin: 2em 0 .5em;
        text-align: right;
        & button {
          display: inline-block;
          margin: 0 1em;
          width: fit-content;
        }
      }
    }

    & .bar {
      border: 1px solid black;
      height: 20px;
      width: 100%;

      & .progress {
        background: $blue;
        height: 20px;
        width: 100%;
        overflow: visible;
        background-size: 0 100%;
        text-align: center;

        & .progress-perc {
          color: $blue;
          mix-blend-mode: difference;
        }
      }
    }
  }
  
}


  