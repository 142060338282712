@import 'src/index.scss';

.suggestion-item {
  width: 100%;
  border-top: 1px solid $gray;
  padding: .5em .2em;
  box-sizing: border-box;
  background: $white;
  cursor: pointer;
}

.suggestion-item-active {
  background: $gray-light;
}

  