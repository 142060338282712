@import "src/index.scss";

header {
    box-sizing: border-box;
    clear: both;
    display: block;
    height: 40px;
    padding: .5em;
    position: relative;
    text-align: left;
    z-index: 9997;
    width: 100%;
    @media (max-width: ($breakpoint-X)) {
        height: auto !important;
        text-align: right;
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
    }

    > *,
    #leds > * {
        display: inline-block;
        margin-right: .5em;
    }
    
    #menu_toggle {
        margin-right: 1em;

        & .fa-bars {
            font-size: x-large;
        }

        @media (max-width: ($breakpoint-X)) {
            position: absolute;
            left: 5px;
            top: 5px;
            & .fa-bars {
                color: black;
                font-size: xx-large;

            }
        }
    }

    #clock,
    #leds {
        @media (max-width: ($breakpoint-X)) {
            display: block;
            width: 100%;
            text-align: right;
            margin-bottom: .5em;
        }
    }

    #program_leds {
        & i {
            display: inline-block;
            margin: 0 5px;
            color: gray;
        }
    }

    #connection_off {
        color: $red;
        animation: blink 1.5s infinite
    }

    #connection_on {
        color: $green;
    }

    #syncing_on {
        animation: blink 1.5s infinite
    }

    @keyframes blink {
        0%{ opacity: 1; }
        19%{ opacity: 0.7; }
        29%{ opacity: 0.5; }
        50%{ opacity: 0; }
        69%{ opacity: 0.5;  }
        79%{ opacity: 0.7;  }
        100%{ opacity: 1; }
    }

    #profile {
        cursor: pointer;
        position: absolute;
        right:0;
        z-index: 9999;
        height: 30px;
        margin-right: 0;
        overflow: hidden;
        transition: all .5s ease;

        @media (max-width: ($breakpoint-X)) {
            display: inline-block;
            margin-left: auto;
            position: relative;
            text-align: right;
        }
        
        &.full {
            height: 200px;
            transform: translateY(0);
        }

        & i {
            margin: 0 .25em;
            vertical-align: middle;

            &.big {
                font-size: 1.75em;
            }
        }

        & #user_menu {
            background: $gray-white;
            padding: .5em 1em;

            & form {
                padding: 0;
            }
    
            & ul {
                font-size: 0.8em;
                list-style: none;
                margin: 0;
                padding: .5em;
                text-align: left;
    
                & li {
                    list-style: none;
                    margin: 0;
                    padding: .25em;
        
                    & i {
                        font-size: 1.75em !important;
                        margin-right: .25em;
                        vertical-align: middle;
                    }
                }
            }

            @media (max-width: ($breakpoint-X)) {
                padding: 0;

                & ul {
                    margin: 1em 0;
                    padding: 0;

                    & li {
                        box-sizing: border-box;
                        display: block;
                    }
                }

                & form,
                & fieldset {
                    text-align: center;
                    padding-top: 0;
                }
            }
        }

    }
    
    .teknox {
        @media (max-width: ($breakpoint-X)) {
            font-size: small;
        }
    }

}

nav {
    background: $white;
    color: $yellow;
    left: 0;
    bottom: 0;
    position: fixed;
    top: 0;
    width: 200px;
    overflow-y: auto;
    z-index: 9998;

    & a {
        color: $black-gray !important;
    }

    & ul {
        font-size: .70em;
        list-style: none;
        margin-top: 50px;
        padding: 0.5em;
        text-align: left;
        text-transform: uppercase;

        & li {
            list-style: none;
            margin: 0;
            padding: 1.25em .15em;

            & i {
                font-size: 1.75em !important;
                margin-right: .25em;
                vertical-align: middle;
            }

            &:hover,
            &.selected {
                border-radius: 5px;
                color: $yellow;
                & a {
                    color: $black-gray !important;
                    text-decoration: underline $yellow 0.3em;
                    text-underline-offset: 0.5em;
                    
                }
            }
        }

        &.controlPanel {
            padding: 0;
            margin-left: 0.3em;
            border-right: 1em;
            & li {
                position: relative;
                padding: 0;

                &:hover,
                &.selected1 {
                    border-radius: 1em;
                    text-decoration: underline $yellow 0.3em;
                    text-underline-offset: 0.5em;
                    background: $white !important;
                    & a {
                        color: $black-gray !important;
                    }
                }    
                &.selected2 {
                    border-radius: 1em;
                    text-decoration: underline $yellow 0.3em;
                    text-underline-offset: 0.5em;
                    background: $white !important;
                    & a {
                        color: $black-gray !important;
                    }
                }
                &.sub {
                    background: $white;
                    font-style: italic;
                    font-size: xx-small;
                }
                & a {
                    display: block;
                    width: 100%;
                    padding: 1.4em .1em;
                }
                & .fa-chevron-down,
                & .fa-chevron-right {
                    right:10px;
                    display: block;
                    position: absolute;
                    font-size: 1.2em !important;
                    top: 1.2em;
                }
                &.back {
                    text-align: center;
                    background: transparent !important;
                    margin-bottom: 2em;
                    border-bottom: 0px;
                    box-sizing: border-box;

                    &:hover {
                        background: transparent !important;    
                    }
                    & a {
                        color: $yellow !important;
                        text-transform: uppercase;
                        font-size: 1.2em;
                        max-width: 80%;
                        padding: 0 0 0 20%;
                        text-align: left;
                    }

                    & i.fa-chevron-left {
                        display: block;
                        position: absolute;
                        font-size: 1.2em !important;
                        top: inherit;
                        left: 10px;
                    }
                }
            }
        }
    }

    &.closed {
        display: none;
    }

    & .close {
        & big {
            cursor: pointer;
            display: none;
            font-weight: bold;
            margin: .25em;
            @media (max-width: ($breakpoint-2X)) {
                display: block !important;
            }        
        }    
    }
}

.premiumAvatar {
    position: relative;
    &::before {
        color: gold;
        content: '\2605';
        font-size: 1.25em;
        position: absolute; 
        right: -.25em;
        top: -.4em;    
    }
}
